import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 登录路由
  {
    component: () => import('@/views/login/login.vue'),
    path: '/login',
    name: 'login'
  },
  // 子路由
  {
    component: () => import('@/views/main.vue'),
    path: '/use',
    redirect: '/index',
    children: [
      {
        component: () => import('@/views/main-index.vue'),
        path: '/index'
      },
      {
        component: () => import('@/views/case/case-manager.vue'),
        path: '/caseManager'
      },
      {
        component: () => import('@/views/case/credit-case.vue'),
        path: '/creditCard'
      },
      {
        component: () => import('@/views/dossier/ele-dossier.vue'),
        path: '/eletronticDossier'
      },
      {
        component: () => import('@/views/litigant-info/litigant-info.vue'),
        path: '/info'
      },
      {
        component: () => import('@/views/media/main-media.vue'),
        path: '/media'
      },
      // 系统设置的路由
      {
        component: () => import('@/views/system-setting/sys-categorysetting.vue'),
        path: '/sys/categorysetting'
      },
      {
        component: () => import('@/views/system-setting/sys-limitsetting.vue'),
        path: '/sys/limitsetting'
      },
      {
        component: () => import('@/views/system-setting/sys-organization.vue'),
        path: '/sys/organizaton'
      },
      {
        component: () => import('@/views/system-setting/sys-usersetting.vue'),
        path: '/sys/usersetting'
      },
      {
        component: () => import('@/views/system-setting/sys-selfsetting.vue'),
        path: '/sys/selfsetting'
      },
      // 绩效管理的路由
      {
        component: () => import('@/views/performance-management/dept-per.vue'),
        path: '/permance/deptper'
      },
      {
        component: () => import('@/views/performance-management/dept-payment.vue'),
        path: '/permance/deptpay'
      },
      {
        component: () => import('@/views/performance-management/personal-per.vue'),
        path: '/permance/perper'
      },
      {
        component: () => import('@/views/performance-management/personal-payment.vue'),
        path: '/permance/perpay'
      },
      // 催收 管理的路由
      {
        component: () => import('@/views/debt/per-debt.vue'),
        path: '/case/per'
      },
      {
        component: () => import('@/views/debt/dept-debt.vue'),
        path: '/case/dept'
      },
      {
        component: () => import('@/views/debt/debt-procress.vue'),
        path: '/case/procress'
      },
      {
        component: () => import('@/views/debt/divide-debt.vue'),
        path: '/case/control'
      },
      // 数据管理的路由
      {
        component: () => import('@/views/data/data-case.vue'),
        path: '/data/case'
      },
      {
        component: () => import('@/views/data/date-template.vue'),
        path: '/data/template'
      },
      {
        component: () => import('@/views/data/data-batch.vue'),
        path: '/data/batch'
      },
      // 呼叫中心的路由
      {
        component: () => import('@/views/tele/tele-num.vue'),
        path: '/tele/num'
      },
      {
        component: () => import('@/views/tele/tele-case.vue'),
        path: '/tele/case'
      }
    ]
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (token || to.path === '/login') {
    next()
  } else {
    next({
      name: 'login'
    })
  }
})
export default router
